(function () {
    'use strict';
    /**
     * @ngdoc function
     * @name app.directive:authorize
     * @authorize directive
     * # authorize
     * shows/hides ui control depending on assigned claims
     */
    const extractOption = (attrValue) => {
        if (attrValue && attrValue.length > 0) {
            const [id, value] = attrValue.split(':');
            return {id, value};
        }
        return null;
    }
    const hasFeature = (feature, featureService) => {
        const useNot = feature.indexOf("!") === 0;
        const featureKey = useNot ? feature.substring(1) : feature;
        return useNot ? !featureService.isActive(featureKey) : featureService.isActive(featureKey);
    }
    angular.module('app').directive('feature', [
            'featureService',
            function (featureService) {
                return {
                    restrict: 'A',
                    link: function (scope, element, attrs) {
                        const defaultDisplay = element[0] && element[0].style  &&  element[0].style.display || '';
                        const makeVisible = function () {
                            element[0].style.display = defaultDisplay;
                            element[0].setAttribute('lasso-visible', true);
                        };
                        const makeHidden = function () {
                            element[0].style.display = 'none';
                            element[0].setAttribute('lasso-visible', false);
                        };
                        var determineVisibility = function (resetFirst) {
                            var result = hasFeature(feature, featureService);
                            if (element[0].style.display == 'none' || element[0].getAttribute('lasso-visible') == false)
                                return;

                            if (resetFirst) {
                                makeVisible();
                            }
                            if (result) {
                                makeVisible();
                            } else {
                                makeHidden();
                            }
                        };
                        const feature = attrs.feature ? (attrs.feature) : '';

                        if (feature != "" && feature.length > 0) {
                            determineVisibility(true);
                        }
                    }
                };
            }]);
    angular.module('app').directive('accountOption', ['accountService', function (accountService) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                const defaultDisplay = element[0] && element[0].style  &&  element[0].style.display || '';
                const makeVisible = function () {
                    element[0].style.display = defaultDisplay;
                    element[0].setAttribute('lasso-visible', true);
                };
                const makeHidden = function () {
                    element[0].style.display = 'none';
                    element[0].setAttribute('lasso-visible', false);
                };
                const determineVisibility = function (resetFirst) {
                    var result = accountService.hasOption(option.id, option.value) || element[0].getAttribute('lasso-visible') == false;
                    if (resetFirst) {
                        makeVisible();
                    }
                    if (result) {
                        makeVisible();
                    } else {
                        makeHidden();
                    }
                };
               
                const option = extractOption(attrs.accountOption);
                if (option && option.id) {
                    determineVisibility(true);
                }
            }
        };
    }]);

})();