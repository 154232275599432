(function () {
    'use strict';

    /**
 * @ngdoc function
 * @name app.directive:fileUpload
 * @fileUpload directive
 * # <file-upload  />
 * uploads file to server (with image preview)
 */

    angular.module('app')
        .directive('fileUpload', function () {
            return {
                template: require('@views/app/file-upload.html').default,
                restrict: 'E',
                scope: { fileTypes: '@', entityId: '@', submitUrl: '@', submitFunction: '&submitFunction', title: '@', singleFile: '@singleFile', dimensions: '@', file: '@', hideFile: '@', controlDisabled: '@', isFacebook: '@', isCreative: '@', creativeNotOnReview: '@', isVideo: '@', isBanner: '@', isCsv: '@', isRequestCreative: '@', isAvatar: '@', hidePreview: '@', width: '@', height: '@', isInvalid: '@', isCarousel: '@', isCanvas: '@', isCanvasProduct: '@', facebookType: '@'},

                controller: ['$scope', '$rootScope', '$parse', 'hub', function ($scope, $rootScope, $parse, hub) {
                    var vm = this;
                    vm.singleFileMode = $scope.singleFile ? true : false;
                    vm.fileTypes = $scope.fileTypes;
                    vm.submitUrl = $scope.submitUrl;
                    vm.submitFunction = $parse($scope.submitFunction);
                    vm.title = $scope.title;
                    vm.files = [];
                    vm.hidePreview = $scope.hidePreview
                    vm.width = $scope.width;
                    vm.height = $scope.height;
                    vm.hideFile = $scope.hideFile;
                    vm.controlDisabled = $scope.controlDisabled == "true";
                    vm.isFacebook = $scope.isFacebook == "true";
                    vm.facebookType = vm.isFacebook ? $scope.facebookType : null;
                    vm.isAvatar = $scope.isAvatar == "true";
                    vm.isCreative = $scope.isCreative == "true";
                    vm.isVideo = $scope.isVideo == "true";
                    vm.isCsv = $scope.isCsv == "true";
                    vm.creativeNotOnReview = $scope.creativeNotOnReview == "true";
                    vm.isBanner = $scope.isBanner ? $scope.isBanner : false;
                    vm.isRequestCreative = $scope.isRequestCreative ? $scope.isRequestCreative : false;

                    vm.flowInitSettings = vm.singleFileMode ? { singleFile: true } : {};

                    $scope.showPopover = function (elem, src) {
                        if (src) {
                            $scope.popupSource = src;
                            $scope.marginLeft = (-vm.dimensions.split('x')[0] / 2) + 'px';
                            $scope.marginTop = 0 + 'px';
                            $scope.popoverIsVisible = true;
                        }
                    };

                    $scope.hidePopover = function () {
                        $scope.popoverIsVisible = false;
                    };

                    if (vm.isVideo) {
                        vm.file = $scope.file;
                    }
                    else {
                        if ($scope.file)
                            vm.imageStrings = [$scope.file];
                        else
                            vm.imageStrings = [];
                    }

                    vm.SetBannerAttributes = function (fileSize) {
                        var bannerAttributes = [];
                        bannerAttributes.push(1);//Image
                        if (fileSize > 2621440)
                            bannerAttributes.push(98);//2.5MB
                        else if (fileSize > 1048576)
                            bannerAttributes.push(97);//1MB
                        else if (fileSize > 512000)
                            bannerAttributes.push(96);//500KB
                        else if (fileSize > 204800)
                            bannerAttributes.push(95);//200KB
                        else if (fileSize > 81920)
                            bannerAttributes.push(9);//80KB
                        else if (fileSize > 40960)
                            bannerAttributes.push(94);//40KB

                        return bannerAttributes;

                    }

                    vm.processFiles = function (flowFile) {
                        if (vm.isFacebook || vm.isBanner) {
                            if ($scope.isCarousel == "true" && flowFile.file.size > 31457280) {
                                vm.warning = 'Minimum with is 600px. Minimum height is 600px.Maximum size is 30 MB.';
                            }
                            else if (flowFile.file.size > 40960) {
                                vm.warning = 'This creative exceeds 40KB which may cause overage charges to accrue.';
                            }
                            else {
                                vm.warning = false;
                            }
                        }

                        var fileReader = new FileReader();
                        fileReader.onload = function (event) {
                            var img = new Image();
                            if (flowFile.file.type === "application/x-zip-compressed" || flowFile.file.type === "application/zip" || flowFile.file.type === "multipart/x-zip") {
                                vm.submitFunction({ file: flowFile.file } );
                            } else if (vm.isBanner) {
                                //set attributes
                                vm.bannerAttributes = vm.SetBannerAttributes(flowFile.file.size);
                                ///end
                                img.onload = function () {
                                    if (this.width > 0 && this.height > 0) {
                                        vm.dimensions = this.width + 'x' + this.height;
                                        $scope.width = this.width;
                                        $scope.height = this.height;
                                        $scope.isInvalid = false;
                                        vm.submitFunction({ file: flowFile.file, dimensions: vm.dimensions, bannerAttributes: vm.bannerAttributes, warning: vm.warning });
                                        var uri = event.target.result;

                                        if (vm.singleFileMode)
                                            vm.imageStrings[0] = uri;
                                        else
                                            vm.imageStrings.push(uri);
                                    }
                                    else {
                                        var message = 'Uploaded file dimensions (' + this.width + 'x' + this.height + ') are unsupported. Please use the standard creative sizes as listed above.';
                                        $rootScope.$broadcast("wrongDimension", { message: message, isCanvasProduct: false});
                                    }
                                }
                                img.src = event.target.result;
                            }
                            else if (vm.isFacebook) {
                                img.onload = function () {
                                    var ratio = this.width / this.height;
                                    if (($scope.facebookType == 1) && (this.width < 1 || this.height < 1)) {
                                        var message = 'Minimum Width is 1. Minimum Height is 1. Maximum size is 30 MB.';
                                        $rootScope.$broadcast("wrongDimension", { message: message });
                                    }
                                    else {
                                        $scope.isInvalid = false;
                                        vm.dimensions = this.width + 'x' + this.height;
                                        var mode = $scope.isCanvasProduct == "true" ? 'canvasProduct' : ($scope.isCarousel == "true" ? 'carousel' : '');
                                        vm.submitFunction({ file: flowFile.file, dimensions: vm.dimensions, mode: mode });
                                        var uri = event.target.result;

                                        if (vm.singleFileMode)
                                            vm.imageStrings[0] = uri;
                                        else
                                            vm.imageStrings.push(uri);
                                    }
                                }
                                img.src = event.target.result;
                            }
                            else if (vm.isVideo) {
                                var v = document.createElement('video');

                                var type = flowFile.file.type.replace('video/', '');
                                if (type == 'x-flv') {
                                    vm.submitFunction({ file: flowFile.file });
                                }

                                var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

                                if (isChrome) {
                                    v.addEventListener('loadedmetadata', onLoadMetaData(flowFile, v));
                                }
                                else {
                                    v.onloadedmetadata = onLoadMetaData(flowFile, v);
                                }
                                v.src = event.target.result;
                            }
                            else if (vm.isCsv) {
                                vm.submitFunction({ file: { data: event.target.result, file: flowFile.file } });
                            }
                            else {
                                vm.submitFunction({ file: flowFile.file });
                                var uri = event.target.result;

                                if (vm.singleFileMode)
                                    vm.imageStrings[0] = uri;
                                else
                                    vm.imageStrings.push(uri);
                            }


                        };

                        if (vm.isCsv)
                            fileReader.readAsText(flowFile.file);

                        else
                            fileReader.readAsDataURL(flowFile.file);

                    };

                    function onLoadMetaData(flowFile, v) {
                        var type = flowFile.file.type.replace('video/', '');

                        var canvas = document.createElement("canvas");
                        canvas.width = v.videoWidth * 1;
                        canvas.height = v.videoHeight * 1;
                        canvas.getContext('2d').drawImage(v, 0, 0, canvas.width, canvas.height);


                        var thumbURL = canvas.toDataURL();

                        var allowedTypes = ['mp4', 'ogg', 'webm', 'flv'];
                        if (flowFile.file.size > 104857600)
                            alert("Maximum file size shouldn't exceeed 100MB");
                        else if (_.some(allowedTypes, type))
                            alert("Video type is not supported: " + type);
                        else {
                            vm.submitFunction({ file: flowFile.file, thumbURL: thumbURL });
                        }
                    }
                    $scope.$on('videoUploaded', function (event, data) {
                        vm.file = data;
                    });

                    $scope.$on('includedGroupsChanged', function (event, data) {
                        vm.controlDisabled = data.controlDisabled;
                    });

                    $scope.$on('selectedImagesChanged', function (event, creative) {
                        if (creative.banner728x90 && vm.dimensions == '728x90')
                            vm.imageStrings = [creative.banner728x90];
                        else if (creative.banner300x250 && vm.dimensions == '300x250')
                            vm.imageStrings = [creative.banner300x250];
                        else if (creative.banner300x600 && vm.dimensions == '300x600')
                            vm.imageStrings = [creative.banner300x600];
                        else if (creative.banner160x600 && vm.dimensions == '160x600')
                            vm.imageStrings = [creative.banner160x600];
                        else if (creative.banner320x50 && vm.dimensions == '320x50')
                            vm.imageStrings = [creative.banner320x50];
                        else if (creative.banner300x50 && vm.dimensions == '300x50')
                            vm.imageStrings = [creative.banner300x50];
                        else if (creative.banner970x90 && vm.dimensions == '970x90')
                            vm.imageStrings = [creative.banner970x90];
                        else if (creative.banner970x250 && vm.dimensions == '970x250')
                            vm.imageStrings = [creative.banner970x250];
                        else if (creative.banner1x1 && vm.dimensions == '1x1')
                            vm.imageStrings = [creative.banner1x1];
                        else if (creative.banner460x120 && vm.dimensions == '460x120')
                            vm.imageStrings = [creative.banner1x1];
                        else if (creative.banner570x70 && vm.dimensions == '570x70')
                            vm.imageStrings = [creative.banner1x1];
                        else if (creative.banner120x240 && vm.dimensions == '120x240')
                            vm.imageStrings = [creative.banner120x240];
                        else if (creative.banner650x136 && vm.dimensions == '650x136')
                            vm.imageStrings = [creative.banner650x136];
                        else if (creative.banner1200x628 && vm.dimensions == '1200x628')
                            vm.imageStrings = [creative.banner1200x628];
                        else if (creative.banner320x480 && vm.dimensions == '320x480')
                            vm.imageStrings = [creative.banner320x480];
                        else if (creative.banner640x480 && vm.dimensions == '640x480')
                            vm.imageStrings = [creative.banner640x480];
                        else if (creative.banner640x640 && vm.dimensions == '640x640')
                            vm.imageStrings = [creative.banner640x640];
                        else if (creative.banner120x600 && vm.dimensions == '120x600')
                            vm.imageStrings = [creative.banner120x600];
                        else if (creative.banner1920x1080 && vm.dimensions == '1920x1080')
                            vm.imageStrings = [creative.banner1920x1080];
                        else if (creative.banner768x1024 && vm.dimensions == '768x1024')
                            vm.imageStrings = [creative.banner768x1024];
                        else if (creative.banner1024x768 && vm.dimensions == '1024x768')
                            vm.imageStrings = [creative.banner1024x768];
                        else if (creative.banner320x100 && vm.dimensions == '320x100')
                            vm.imageStrings = [creative.banner320x100];
                        else if (creative.banner300x1050 && vm.dimensions == '300x1050')
                            vm.imageStrings = [creative.banner300x1050];
                        else if (creative.banner250x250 && vm.dimensions == '250x250')
                            vm.imageStrings = [creative.banner250x250];
                        else if (creative.banner336x280 && vm.dimensions == '336x280')
                            vm.imageStrings = [creative.banner336x280];
                        else if (creative.banner480x320 && vm.dimensions == '480x320')
                            vm.imageStrings = [creative.banner480x320];
                        else if (creative.banner320x250 && vm.dimensions == '320x250')
                            vm.imageStrings = [creative.banner320x250];
                        else if (creative.banner970x550 && vm.dimensions == '970x550')
                            vm.imageStrings = [creative.banner970x550];
                        else if (creative.banner468x60 && vm.dimensions == '468x60')
                            vm.imageStrings = [creative.banner468x60];
                        else
                            vm.imageStrings = [];
                    });

                    $scope.$on('creativeImageRemoved', function () {
                        if (vm.isCreative && vm.imageStrings && vm.imageStrings[0])
                            vm.imageStrings = [];
                    });
                    $scope.$on('creativeVideoRemoved', function () {
                        if (vm.isCreative) {
                            vm.file = '';
                        }
                    });

                    return vm;
                }],

                controllerAs: 'vm'
            }
        });

})();
